import { isAxiosError } from 'axios';
import { logout } from 'src/common';

export const errorHandler = (error, callback) => {
  if (isAxiosError(error)) {
    switch (error.response.status) {
      case 401:
        logout();
        window.location.reload();
        break;
      case 400:
        callback?.('VALIDATION_ERROR', error.response.data.error.details[0].message, error.response.data);
        break;
      case 403:
      case 404:
        callback?.(error.response.data.code, error.response.data.message);
        break;
      case 500:
        callback?.(error.response.data.code, 'database error');
    }
  }
};
